'use strict';

/**
 * Created by ivan on 23.09.2016.
 */

(function ($) {
    'use strict';

    /*
        обработка скрола и появление левого меню
     */

    var $about = $('#about');
    var $mainBlock = $('#activities');
    var $fixedNav = $('.fixed-nav');
    var $fixedNavList = $('.fixed-nav__list');
    var $menuFirst = $('#left-menu-1');
    var $fixedNav1 = $('#left-menu-1 ul');
    var $fixedNav2 = $('#left-menu-2 ul');
    var $map = $('#contacts');
    var $fixedNavListPos = $fixedNavList.position().top;
    $(window).resize(function () {
        $fixedNavListPos = $fixedNavList.position().top;
    });

    var menuMapHandler = function() {
        if ($(window).scrollTop() > $about.outerHeight() + $mainBlock.outerHeight() - $fixedNavList.width() - $fixedNavListPos) {
            $fixedNavList.addClass('fixed-nav__list--fix');
            $fixedNavList.css({ bottom: $(window).height() - $map.get(0).getBoundingClientRect().bottom + $map.outerHeight() - 6 + 'px' });
        } else {
            $fixedNavList.removeClass('fixed-nav__list--fix');
            $fixedNavList.css({ bottom: 'auto' });
        }
    }

    if ($about.length) {
        $.fn.hoverOther = function (other) {
            var $t = $(this);
            var lis = $t.find('li');
            var otherLis = other.find('li');
            lis.hover(function () {
                var $t = $(this);
                var index = lis.index($t);
                otherLis.eq(index).addClass('hover');
            }, function () {
                var $t = $(this);
                var index = lis.index($t);
                otherLis.eq(index).removeClass('hover');
            });
        };
        $fixedNav1.hoverOther($fixedNav2);
        $fixedNav2.hoverOther($fixedNav1);
        var init = function init() {
            var h = $(window).scrollTop() + $(window).height() - $about.outerHeight() - $fixedNav1.position().top;
            $fixedNav1.css({ clip: 'rect(0px, auto, auto, ' + h + 'px)' });
            $fixedNav2.css({ clip: 'rect(0px, ' + h + ', 200px, 0px)' });
            // if ($map.length) {
            //   menuMapHandler();
            // }
        };
        $(init);
        $(window).on('scroll resize', init);

        // создание структуры для трекинга меню
        var block = [];
        $fixedNav1.find('a').each(function (index, element) {
            var $block = $(element.hash);
            block.push( {
                hash: element.hash,
                block: $block,
                offsetTop: $block.offset().top
            } );
        });
        block = block.reverse();
        // обработка трекинга меню при скроле

        var trackingMenu = function () {
            var scrollTop = $(window).scrollTop();
            block.forEach(function (element, index, list) {
                var scrollTopOffset = scrollTop + $(window).height() * 0.2;
                if (element.offsetTop < scrollTopOffset && element.offsetTop + element.block.height() > scrollTopOffset ) {
                    $fixedNav1.find('.active').removeClass('active');
                    $fixedNav2.find('.active').removeClass('active');

                    $fixedNav1.find('a[href="' + element.hash + '"]').parent().addClass('active');
                    $fixedNav2.find('a[href="' + element.hash + '"]').parent().addClass('active');
                }

                if(element.hash == '#contacts'){
                    if(element.offsetTop < scrollTop + $(window).height() - element.block.height() * 0.5){
                        $fixedNav1.find('.active').removeClass('active');
                        $fixedNav2.find('.active').removeClass('active');

                        $fixedNav1.find('a[href="' + element.hash + '"]').parent().addClass('active');
                        $fixedNav2.find('a[href="' + element.hash + '"]').parent().addClass('active');
                    }
                }
            });
        };
        $(window).on('scroll', trackingMenu);
        trackingMenu();
    } else {
        $menuFirst.addClass('fixed-nav--hide');
    }

    // if ($map.length) {
    //     $(window).on('scroll resize', menuMapHandler);
    // }

    $('.top_menu_h a').click(function () {

        if($(window).scrollTop() == 0){
            setTimeout(init, 50);
        }

        $('html, body').animate({
            scrollTop: 0
        }, 500);
    });

    /*
    * Обработка нажатия на левое меню
    * */
    var leftMenu = $('.fixed-nav');
    if (leftMenu.length > 0) {
        leftMenu.find('a').on({
            click: function click(event) {

                var $this = $(this);
                var idBlock = $this.attr('href');
                var $scrollToBlock = $(idBlock);
                var url = window.location.origin + '/' + idBlock;
                if ($scrollToBlock.length > 0) {
                    event.preventDefault();
                    var toScroll = $scrollToBlock.offset().top;
                    history.pushState({}, document.title, url);
                    $('html, body').animate({
                        scrollTop: toScroll
                    }, 500);
                    leftMenu.find('.active').removeClass('active');
                    leftMenu.find("[href*='" + idBlock + "']").parent().addClass('active');
                } else {
                    window.location = url;
                }
            }
        });
        if (window.location.hash !== "") {
            leftMenu.find('.active').removeClass('active');
            leftMenu.find("[href*='" + window.location.hash + "']").parent().addClass('active');
        }
    }

    $('.fixed-nav a[href="#about"]').on('click', function () {
        $('.top_menu_h a[href="#main"]').tab('show');
    });

    /*
    * Нативная функция проверки класса
    * */
    function hasClass(target, className) {
        return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
    }

    var isBusy = false;
    $(document.body).on('click', '.load_next.news_more_1 a', function (e) {
        e.preventDefault();
        var $this = $(this);

        if (isBusy) return true;

        if ($this.attr('data-next') == 0) {
            isBusy = true;
            $('.news_block_type_1 .item:gt(3)').slideUp(600, function () {
                $(this).remove();
                $this.text('Показать еще');
                $this.attr('data-next', 2);
                isBusy = false;
            }).animate({ opacity: 0 }, { queue: false, duration: 600 });
            return false;
        }

        if ($this.attr('data-next') != 0) {
            isBusy = true;
            $.get('/get-news-one?page=' + $this.attr('data-next'), function (data) {
                var $data = $(data).filter('.item');
                $('.news_block_type_1 .news__items').append($data.hide().css('opacity', 0));
                $data.slideDown(600, function () {
                    //parent.remove();
                    $this.attr('data-next', 0);
                    $this.text('Скрыть');
                    isBusy = false;
                });
                $data.animate({ opacity: 1 }, { queue: false, duration: 600 });
            });
        } else {
            // parent.remove()
        }
    }).on('click', '.open_this_news', function (e) {
        e.preventDefault();
        var $this = $(this);
        $('.full_text_new').hide();
        $this.find('.full_text_new').show();
    }).on('click', '.load_next.news_more_one a', function (e) {
        e.preventDefault();
        var $this = $(this);

        if (isBusy) return true;

        if ($this.attr('data-next') == 0) {
            console.log(111);
            isBusy = true;
            var ss = $('.news_block_type_1 .item:gt(2)').slideUp(600, function () {
                $(this).remove();
                $this.text('Показать еще');
                $this.attr('data-next', 2);
                isBusy = false;
            }).animate({ opacity: 0 }, { queue: false, duration: 600 });
            console.log(ss);
            return false;
        }

        if ($this.attr('data-next') != 0) {
            isBusy = true;
            $.get('/get-news-one?page=' + $this.attr('data-next'), function (data) {
                var $data = $(data).filter('.item');
                $('.news_block_type_1 .news__items').append($data.hide().css('opacity', 0));
                $data.slideDown(600, function () {
                    //parent.remove();
                    $this.attr('data-next', 0);
                    $this.text('Скрыть');
                    isBusy = false;
                }).animate({ opacity: 1 }, { queue: false, duration: 600 });
            });
        } else {
            // parent.remove()
        }
    }).on('click', '.load_next.news_more_2 a', function (e) {
        e.preventDefault();
        var $this = $(this);

        if (isBusy) return true;

        if ($this.attr('data-next') == 0) {
            isBusy = true;
            $('.news-big .item:gt(2)').slideUp(600, function () {
                $(this).remove();
                $this.text('Показать еще');
                $this.attr('data-next', 2);
                isBusy = false;
            }).animate({ opacity: 0 }, { queue: false, duration: 600 });
            return false;
        }

        if ($this.attr('data-next') != 0) {
            isBusy = true;
            $.get('/get-news-two?page=' + $this.attr('data-next'), function (data) {
                var $data = $(data);
                var $items = $data.filter('.item');
                $items.css({ 'display': 'none', 'opacity': '0' });
                $('.news-big').append($items);
                var def = [];
                $items.each(function (index,elem) {
                    def.push((function () {
                        var dfd = $.Deferred();
                        setTimeout(function () {
                            $(elem).slideDown(600, function () {
                                dfd.resolve();
                            }).animate({ opacity: 1 }, { queue: false, duration: 600 });
                        }, index * 300);
                        return dfd.promise();
                    })());
                });

                $.when.apply($, def).done(function () {
                     var $next = $data.filter('.load_next.news_more_2').find('a');
                     if ($next.attr('data-next') == 0) {
                         $('.load_next.news_more_2').find('a').text('Скрыть');
                     }
                     $this.attr('data-next', $next.attr('data-next'));
                     isBusy = false;
                });

            });
        }
    })
    /*.on('click','.news .item a , .load_one_new' , function (e) {
        e.preventDefault();
         $.get( $(this).attr('href') , function (data) {
            let d = JSON.parse(data);
            $('#myModalLabel').text(d.name);
            let text = '<img style="max-width:200px;float: left; margin: 0 15px 15px 0" src="uploads/' + d.icon + '">' + d.text;
            $('#content_news').html(text);
            $('#myModal').modal('show')
        });
     })*/;

    var bannerTop = [];
    var bannerBottom = [];
    $.get('/get-banners', function (data) {
        var d = JSON.parse(data);

        d.forEach(function (el, i) {
            if (el.type == 0) bannerTop.push(el);else bannerBottom.push(el);
        });

        bannerTop.sort(function (a, b) {
            if (a.priority > b.priority) return 1;
            if (a.priority < b.priority) return -1;
        });
        bannerBottom.sort(function (a, b) {
            if (a.priority > b.priority) return 1;
            if (a.priority < b.priority) return -1;
        });

        initBannerTop(iTop);
        initBannerRight(iRight);
    });
    var iTop = -1;
    var iRight = -1;
    function initBannerTop(index) {
        /*iTop = index + 1;
         if ( iTop >=  bannerTop.length ) {
            iTop = 0;
        }
        $('#top_b').html('<a href="' + bannerTop[iTop]['link'] + '" target="_blank"><img src="/uploads/' + bannerTop[iTop]['image'] + '"></a>');
         setTimeout(function () {
            initBannerTop(iTop);
        },bannerTop[iTop]['time_view'] * 1000);*/

        showBanners(document.getElementById('top_b'), bannerTop, -1);
    }

    function showBanners(element, banners, index) {
        // оборачиваем елемент jquery
        var $element = $(element);

        // определяем новый индекс
        var newIndex = index + 1;
        if (newIndex >= banners.length) {
            newIndex = 0;
        }

        // создаем картинку
        $('<img />').attr('src', '/uploads/' + banners[newIndex]['image'])
        // ждем загрузки
        .load(function () {
            var $newBanner = $('<a href="' + bannerBottom[newIndex]['link'] + '" target="_blank">' + this.outerHTML + '</a>');
            var $a = $element.find('a');
            if ($a.length > 0) {
                $a.fadeOut(200, function () {
                    $element.html($newBanner);
                    $newBanner.fadeIn(200).css("display", "inline-block");
                });
            } else {
                $element.html($newBanner);
                $newBanner.fadeIn(200).css("display", "inline-block");
            }
        });
        setTimeout(function () {
            showBanners(element, banners, newIndex);
        }, bannerBottom[newIndex]['time_view'] * 1000);
    }

    function initBannerRight(index) {
        /*iRight = index + 1;
         if ( iRight >=  bannerBottom.length ) {
            iRight = 0;
        }*/

        $('.adv_block .cell-inner').each(function (index, element) {
            showBanners(element, bannerBottom, -1);
        });

        /*$('<img src="/uploads/' + bannerBottom[iRight]['image'] + '" >')
        .load(function () {
                var $newBanner = $('<a href="' + bannerBottom[iRight]['link'] + '" target="_blank">' + this.outerHTML + '</a>');
                var $a = $('.adv_block .cell-inner a');
                if($a.length > 0) {
                    $a.fadeOut(200, function () {
                        //$newBanner.hide();
                        $newBanner.fadeIn(200);
                        $('.adv_block .cell-inner').html($newBanner);
                        //$newBanner.show();
                        //$newBanner.fadeIn(200);
                    })
                }else{
                    $('.adv_block .cell-inner').html($newBanner[0].outerHTML);
                    $newBanner.fadeIn(200);
                }
            }
        );
         setTimeout(function () {
            initBannerRight(iRight);
        },bannerBottom[iRight]['time_view'] * 1000);*/
    }
})(jQuery);
